<template>
  <div class="product-bg">
    <div class="row">
      <div class="col-md-12">
        <p class="h1 product-bigTitle mt-2 text-center">
          OUR PRODUCTS & SERVICES
        </p>
      </div>
    </div>
    <div class="product-container mt-4"  data-aos="fade-up"  data-aos-delay="100">
      <div class="product-card" v-for="item in products">
        <div class="d-flex justify-content-center">
          <img :src="item.img" class="product-img" />
        </div>

        <span class="product-title">{{ item.title }}</span>
        <div class="d-flex my-2" style="gap: 5px; flex-wrap: wrap">
          <div v-for="gettype in item.types" class="type-container">
            <span>{{ gettype }}</span>
          </div>
        </div>

        <span class="product-text">{{ item.subtitle }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  components: {},
  data: () => ({
    products: [
      {
        img: "/img/fintech.svg",
        types: ["Digital Payment", "Payment Gateway"],
        title: "Fintech",
        subtitle: `We provide solution for digital payment solutions and other services which promote financial inclusion and provide personalized alternatives to traditional financial institutions.`,
      },
      {
        img: "/img/blockchain.svg",
        types: ["Smart Connect", "ICO", "NFT"],
        title: "Blockchain",
        subtitle: ` Blockchain is a technology that allows secure and transparent recording and verification of transactions or data. We provide solutions for peer-to-peer transactions, smart contracts, and decentralized applications using blockchain.`,
      },
      {
        img: "/img/martech.svg",
        types: [
          "Business Strategy",
          "E-Commerce",
          "Space & Environment",
          "Market Access & Insights",
          "Marketing & Branding",
          "Licensed Merchandise",
        ],
        title: "Martech",
        subtitle: ` We provide software to help marketers understand customers better, personalize messaging, and measure marketing effectiveness.`,
      },
      {
        img: "/img/mobile.svg",
        types: ["Design Mobile UI/UX", "Mobile Development", "API Integration"],
        title: "Mobile Application",
        subtitle: ` Mobile apps are typically downloaded and installed from app stores or marketplaces specific to the device's operating system. We build customized application based on requirement.`,
      },
      {
        img: "/img/iot.svg",
        types: ["Software Archictecture", "Web/Mobile Synchronization"],
        title: "Internet of Thing (IoT)",
        subtitle: ` The Internet of Things (IoT) refers to the connected devices embedded with software, and connectivity capabilities that enable them to collect and exchange data over the internet.`,
      },
      {
        img: "/img/web.svg",
        types: ["Customized UI/UX", "Branding & Copywriting"],
        title: "Web Portal",
        subtitle: ` We create web pages hosted on the internet and accessible through a unique domain name and serve as an online presence for individuals, businesses that want to share information, provide services, or engage with an audience.`,
      },
    ],
  }),

  mounted() {
    AOS.init();
  },
};
</script>

<style>
/* .product-description{
        font-size:10px; 
    } */

.services-word {
  border: solid 1px #182046;
  padding: 3px 8px;
  border-radius: 50px;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 5px;
  color: #fff;
  background-color: #182046;
}
.product-img {
  width: 200px;
}

.product-bg {
  background-image: url(/img/Background.png);
  background-size: cover;
  background-position: center center;
  padding: 70px 15px 50px 15px;
}

.product-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 350px));
  gap: 10px;
  justify-content: center;
  margin-bottom: 50px;
}

.product-card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
}

.product-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: #8c93a2;
  margin-top: 5px;
  font-family: Roboto;
}

.product-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #33495c;
}

.product-bigTitle {
  font-style: normal;
  font-weight: 800;
  font-size: clamp(32px, 5vw, 50px);
  line-height: clamp(40px, 5vw, 73px);
  /* or 146% */

  color: #33495c;
  backdrop-filter: blur(1.62379px);
}

.product-description {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;

  color: #182046;
}

.service_btn {
  padding: 15px;
  background: linear-gradient(99.13deg, #1088d6 6.21%, #1256d3 91.79%),
    linear-gradient(122.29deg, #13e3b1 14.46%, #0c94b1 78.74%),
    linear-gradient(113.74deg, #f9ab7d 27.92%, #f95e70 69.53%);
  box-shadow: inset -2px -4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #fff;
}

.service-word {
  color: #182046;
}

.type-container {
  background-color: #1088d6;
  padding: 3px 6px;
  border-radius: 4px;
  color: #fff;
  width: auto;
  font-weight: 400;
  font-size: 12px;
  font-family: Roboto;
}
</style>
